<template>
  <div id="salary" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">NOTIFICACIONES</v-row>
      <v-row no-gutters justify="end" class="mr-12">
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <router-link to="/addNotification" id="nuevaNotificacion">
              <button class="breakSearchE botonAmarillo">
                Alta de Notificacion
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="notifications"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Notificaciones por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning"
                >No hay datos en sistema ..</v-alert
              >
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">{{ item.tituloComunicado }}</td>
                  <td class="text-sm-center">
                    {{ item.estatus.nombreEstatus }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.fechaInicio.substr(0, 10) }} -
                    {{ item.fechaFin.substr(0, 10) }}
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Notificacion</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" scrollable max-width="370px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de Notificación</v-card-title
        >
        <v-card-text style="height: 300px" id="info" class="mb-n12">
          <div
            class="form-group mt-5"
            v-if="notificationSelect.empresa != null"
          >
            <label>Empresa:</label>
            {{ notificationSelect.empresa.razonSocial }}
          </div>
          <div class="form-group" v-if="notificationSelect.area != null">
            <label>Area:</label>
            {{
              notificationSelect.area == null
                ? notificationSelect.empleado.area.nombreArea
                : notificationSelect.area.nombreArea
            }}
          </div>
          <div class="form-group" v-if="notificationSelect.puesto != null">
            <label>Puesto:</label>
            {{ notificationSelect.puesto != null ? notificationSelect.puesto.nombrePuesto : ""}}
          </div>
          <div class="form-group" v-if="notificationSelect.empleado != null">
            <label>Empleado Asignado:</label>
            {{
              notificationSelect.empleado.nombre +
              " " +
              notificationSelect.empleado.apellidoPaterno
            }}
          </div>
          <div class="form-group">
            <label>Estatus:</label>
            {{ notificationSelect.estatus.nombreEstatus }}
          </div>
          <div class="form-group">
            <label>Envio:</label>
            {{
              notificationSelect.empleadoEnvia.nombre +
              " " +
              notificationSelect.empleadoEnvia.apellidoPaterno
            }}
          </div>
          <div class="form-group">
            <label>Notificacion:</label>
            {{ notificationSelect.tituloComunicado }}
          </div>
          <div class="form-group">
            <label>Descripción:</label>
            {{ notificationSelect.descripcionComunicado }}
          </div>
          <div class="form-group">
            <label>Fecha de Inicio:</label>
            {{ notificationSelect.fechaInicio.substr(0, 10) }}
          </div>
          <div class="form-group">
            <label>Fecha de Fin:</label>
            {{ notificationSelect.fechaFin.substr(0, 10) }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "N°",
          align: "center",
          value: "id",
        },
        {
          text: "Notificacion",
          align: "center",
          value: "tituloComunicado",
        },
        {
          text: "Estatus",
          align: "center",
          value: "estatus.nombreEstatus",
        },
        {
          text: "Fecha de publicación",
          align: "center",
          value: "fechaInicio",
        },
        {
          text: "Editar  /   Ver  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      notifications: [],
      notificationSelect: {
        id: 1,
        empresa: { razonSocial: "Empresa A" },
        area: { nombreArea: "RH" },
        puesto: { nombrePuesto: "Puesto  1" },
        empleado: {
          nombre: "Pedro",
          apellidoPaterno: "",
          area: { nombreArea: "RH" },
          puesto: { nombrePuesto: "Puesto  1" },
        },
        estatus: { nombreEstatus: "Vigente por publicar" },
        empleadoEnvia: { nombre: "Pedro", apellidoPaterno: "" },
        tituloComunicado: "Convocatoria",
        descripcionComunicado: "Papeles",
        fechaInicio: "15/05/20",
        fechaFin: "04/06/20",
      },
      respuesta: "",
      advice: false,
      id: "",
      error: false,
      confirmation: false,
      edit: false,
      vacio: false,
      titulo: "",
      comunicado: "",
    };
  },
  methods: {
    editItem(item) {
      localStorage.idNotificacion = item.id;
      this.$router.push("/editNotification");
    },

    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion() {
      this.errors = [];
      if (this.titulo == "" || this.titulo == " ") {
        this.errors.push("Se debe especificar nombre de la Notificacion.");
      }
      if (this.comunicado == "" || this.comunicado == " ") {
        this.errors.push("Se debe especificar nombre de la Notificacion.");
      }
      if (this.errors.length == 0) {
        this.edit = false;
        this.vacio = false;
        return this.editar();
      } else {
        this.vacio = true;
      }
    },
    editar() {
      console.log("El id para la api es" + this.id);
      this.respuesta = "";
      this.show=true;
      axios
        .put(Server + "/modificarNotificacion/" + this.id, {
          event: this.titulo,
          eventText: this.comunicado,
        },
          {
            headers: {
              Authorization: localStorage.token,
            },
          })
        .then((response) => {
          console.log(response);
          this.id = "";
          this.titulo = "";
          this.comunicado = "";
          this.respuesta = "El elemento fue actualizado con éxito";
          this.listar();
          this.show=false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show=false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.titulo = "";
            this.comunicado = "";
            this.respuesta = "El elemento no fue actualizado";
            this.confirmation = false;
          }
        });
    },
    /*
      temporal sirve para mostrar el nombre del dato a eliminar en la Alerta ayuda al usuario a no borrar el equivocado
      guarda solo el id del dato
    */
    temporal(item) {
      //console.log("El id es: " + item.id);
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar la notificación " +
        item.tituloComunicado +
        " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.id);
      this.respuesta = "";
      this.show=true;
      axios
        .delete(Server + "/notificaciones/" + this.id,
          {
            headers: {
              Authorization: localStorage.token,
            },
          })
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.respuesta = "La notificación fue eliminada con éxito.";
          this.listar();
          this.show=false;
          this.confirmation = true;
        })
        .catch((e) => {
          //console.log(e);
          this.show=false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "La notificación no fue eliminada.";
            this.confirmation = true;
          }
        });
    },
    viewItem(item) {
      //console.log("Ingreso a detalle" + item.id);
      this.notificationSelect = item;
      this.dialog = true;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/notificaciones/empleado-envia/" + localStorage.empleadoIdGlobal, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.notifications = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    aux() {
      this.edit = false;
      this.titulo = "";
      this.comunicado = "";
      this.vacio = false;
    },
  },
  created() {
    this.listar();
  },
};
</script>